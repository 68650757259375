<template>
  <div>
    <p class="text-dark mt-6">
      Authorize refund of
      <span class="font-bold">{{
        breakdown.purchaseAmount | formatMoney
      }}</span>
      from my wallet
      <span
        v-if="
          purchaseDetail.customerFirstName || purchaseDetail.customerLastName
        "
        >to </span
      >{{ purchaseDetail.customerFirstName | capitalize }}
      {{ purchaseDetail.customerLastName | capitalize }} for
      {{ purchaseDetail.title | capitalize }}.
    </p>
    <p class="text-dark mt-6 mb-3 font-semibold">Breakdown</p>

    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <p class="text-darkGrey mr-2">Purchase amount</p>
        <svg
          @click="handleEvent"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          v-tooltip.right="{
            content: 'This is the amount you were settled for this purchase'
          }"
        >
          <path
            d="M6.33325 9.50016H7.66659V10.8335H6.33325V9.50016ZM6.33325 4.16683H7.66659V8.16683H6.33325V4.16683ZM6.99325 0.833496C3.31325 0.833496 0.333252 3.82016 0.333252 7.50016C0.333252 11.1802 3.31325 14.1668 6.99325 14.1668C10.6799 14.1668 13.6666 11.1802 13.6666 7.50016C13.6666 3.82016 10.6799 0.833496 6.99325 0.833496ZM6.99992 12.8335C4.05325 12.8335 1.66659 10.4468 1.66659 7.50016C1.66659 4.5535 4.05325 2.16683 6.99992 2.16683C9.94659 2.16683 12.3333 4.5535 12.3333 7.50016C12.3333 10.4468 9.94659 12.8335 6.99992 12.8335Z"
            fill="#6B6873"
          />
        </svg>
      </div>
      <p>{{ breakdown.purchaseAmount | formatMoney }}</p>
    </div>

    <div
      v-if="breakdown.accruedPenalty"
      class="flex justify-between items-center mt-4"
    >
      <div class="flex items-center">
        <p class="text-darkGrey mr-2">Penalty fee</p>
        <svg
          @click="handleEvent"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          v-tooltip.right="{
            content:
              'This is the fee you incurred for processing the refund late.'
          }"
        >
          <path
            d="M6.33325 9.50016H7.66659V10.8335H6.33325V9.50016ZM6.33325 4.16683H7.66659V8.16683H6.33325V4.16683ZM6.99325 0.833496C3.31325 0.833496 0.333252 3.82016 0.333252 7.50016C0.333252 11.1802 3.31325 14.1668 6.99325 14.1668C10.6799 14.1668 13.6666 11.1802 13.6666 7.50016C13.6666 3.82016 10.6799 0.833496 6.99325 0.833496ZM6.99992 12.8335C4.05325 12.8335 1.66659 10.4468 1.66659 7.50016C1.66659 4.5535 4.05325 2.16683 6.99992 2.16683C9.94659 2.16683 12.3333 4.5535 12.3333 7.50016C12.3333 10.4468 9.94659 12.8335 6.99992 12.8335Z"
            fill="#6B6873"
          />
        </svg>
      </div>
      <p>{{ breakdown.accruedPenalty | formatMoney }}</p>
    </div>

    <div
      v-if="breakdown.topUpRequired"
      class="flex justify-between items-center mt-4"
    >
      <div class="flex items-center">
        <p class="text-darkGrey mr-2">Required top up</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          v-tooltip.right="{
            content:
              'This is the amount you need to add to your wallet to make the refund.'
          }"
        >
          <path
            d="M6.33325 9.50016H7.66659V10.8335H6.33325V9.50016ZM6.33325 4.16683H7.66659V8.16683H6.33325V4.16683ZM6.99325 0.833496C3.31325 0.833496 0.333252 3.82016 0.333252 7.50016C0.333252 11.1802 3.31325 14.1668 6.99325 14.1668C10.6799 14.1668 13.6666 11.1802 13.6666 7.50016C13.6666 3.82016 10.6799 0.833496 6.99325 0.833496ZM6.99992 12.8335C4.05325 12.8335 1.66659 10.4468 1.66659 7.50016C1.66659 4.5535 4.05325 2.16683 6.99992 2.16683C9.94659 2.16683 12.3333 4.5535 12.3333 7.50016C12.3333 10.4468 9.94659 12.8335 6.99992 12.8335Z"
            fill="#6B6873"
          />
        </svg>
      </div>
      <p>
        {{ breakdown.expectedTopUpAmount | formatMoney }}
      </p>
    </div>

    <div
      v-if="breakdown.totalRefundAmount !== breakdown.purchaseAmount"
      class="flex justify-between items-center mt-4"
    >
      <div class="flex items-center">
        <p class="text-darkGrey mr-2">Total</p>
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
        >
          <path
            d="M6.33325 9.50016H7.66659V10.8335H6.33325V9.50016ZM6.33325 4.16683H7.66659V8.16683H6.33325V4.16683ZM6.99325 0.833496C3.31325 0.833496 0.333252 3.82016 0.333252 7.50016C0.333252 11.1802 3.31325 14.1668 6.99325 14.1668C10.6799 14.1668 13.6666 11.1802 13.6666 7.50016C13.6666 3.82016 10.6799 0.833496 6.99325 0.833496ZM6.99992 12.8335C4.05325 12.8335 1.66659 10.4468 1.66659 7.50016C1.66659 4.5535 4.05325 2.16683 6.99992 2.16683C9.94659 2.16683 12.3333 4.5535 12.3333 7.50016C12.3333 10.4468 9.94659 12.8335 6.99992 12.8335Z"
            fill="#6B6873"
          />
        </svg> -->
      </div>
      <p class="text-lg font-bold">
        {{ breakdown.totalRefundAmount | formatMoney }}
      </p>
    </div>

    <div v-if="breakdown.topUpRequired">
      <div class="flex justify-between items-center my-4">
        <div>
          <p class="text-dark mt-6 mb-3 font-semibold">Wallet balance</p>
          <p class="text-dark">
            Your balance is {{ breakdown.walletBalance | formatMoney }}
          </p>
        </div>
        <SecondaryButton
          class="mt-8"
          text="Fund wallet"
          @click="$emit('fund')"
        />
      </div>

      <div
        class="spam-notice mb-6 py-6 bg-lightRed-2 rounded-lg border border-error-200 bg-'#FFF7F5'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M6.75 4.25H8.25V5.75H6.75V4.25ZM6.75 7.25H8.25V11.75H6.75V7.25ZM7.5 0.5C3.36 0.5 0 3.86 0 8C0 12.14 3.36 15.5 7.5 15.5C11.64 15.5 15 12.14 15 8C15 3.86 11.64 0.5 7.5 0.5ZM7.5 14C4.1925 14 1.5 11.3075 1.5 8C1.5 4.6925 4.1925 2 7.5 2C10.8075 2 13.5 4.6925 13.5 8C13.5 11.3075 10.8075 14 7.5 14Z"
            fill="#DE5F35"
          />
        </svg>
        <p class="text-sm text-red-800 mr-6">
          Your wallet balance is not enough to perform this refund. Fund your
          wallet with
          <span class="font-semibold text-base">
            {{ breakdown.expectedTopUpAmount | formatMoney }}</span
          >
          to make the refund.
        </p>
      </div>
    </div>

    <div>
      <p class="text-dark mt-8 mb-3 font-semibold">
        Select your reason for the refund?
      </p>
      <Checkbox :options="refundReasons" @selected="setRefundReason" />
      <Input
        v-if="selectedReason === 'Others'"
        width="w-full"
        placeholder="Enter your reason here"
        class="mt-8"
        v-model="reason"
        errorText="Please enter a reason for this refund"
        :validation="rules.others"
      />
    </div>

    <Button
      class="mt-8"
      :disabled="disableButton"
      :text="`Refund ${formatMoney(breakdown.totalRefundAmount)}`"
      width="w-full"
      :loading="loading"
      @click="initiateRefund"
    />
  </div>
</template>

<script>
import Hr from "@/UI/Hr";
import Input from "@/UI/Input";
import Checkbox from "@/UI/Checkbox";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/SecondaryButton";
import { mapState, mapActions } from "vuex";
import {
  GET_REFUND_REASONS,
  REFUND_PURCHASE
} from "@/utils/api/purchase.js";
export default {
  components: {
    Hr,
    Button,
    SecondaryButton,
    Checkbox,
    Input,
    Modal: () => import("@/UI/Modal")
  },
  data() {
    return {
      showTooltip: "",
      refundReasons: [],
      reason: "",
      selectedReason: "",
      loading: false,
      showFundAccount: false
    };
  },
  props: {
    purchaseDetail: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    rules() {
      return {
        others: this.reason !== "",
        // balance:
        //   this.breakdown.walletBalance >= this.breakdown.totalRefundAmount
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    ...mapState({
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      liveMode: state => state?.auth?.liveMode,
      breakdown: state => state?.purchase?.refundBreakdown,
      walletBalance: state =>
        state?.dashboard?.wallet?.settlementWallet?.balance
    })
  },
  mounted() {
    this.getRefundReasons();
  },
  methods: {
    ...mapActions("dashboard", ["getMerchantWallet"]),
    ...mapActions("purchase", ["getRefundBreakdown", "clearBreakdown"]),
    getWalletInfo() {
      this.getMerchantWallet({
        id: this.merchantOutletId,
        liveMode: this.liveMode
      })
    },

    handleShowTooltip(type) {
      this.showTooltip = !type;
    },

    handleEvent(e) {
      e.stopPropagation();
    },

    setRefundReason(e) {
      if (e === "Others") {
        this.selectedReason = "Others";
        this.reason = "";
      } else {
        this.selectedReason = "";
        this.reason = e;
      }
    },

    getRefundReasons() {
      GET_REFUND_REASONS({ liveMode: this.liveMode })
        .then(res => {
          this.refundReasons = res.data.data;
          this.refundReasons.push("Others");
        })
        .catch(err => {});
    },

    initiateRefund() {
      this.loading = true;
      REFUND_PURCHASE({
        data: {
          purchaseId: this.purchaseDetail.id,
          refundReason: this.reason
        },
        liveMode: this.liveMode
      })
        .then(res => {
          this.loading = false;
          this.$emit("success");
        })
        .catch(err => {
          this.loading = false;
        });
    },

    formatMoney(value) {
      let formattedNumber;
      if (value === null || value === undefined) {
        formattedNumber = 0;
      } else {
        formattedNumber = new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
          currencyDisplay: "symbol"
        })
          .format(value)
          .replace(/(\.|,)00$/g, "");
      }
      return formattedNumber;
    }
  },
  destroyed() {
    clearTimeout(this.clearBreakdown());
  }
};
</script>

<style scoped>
.tooltip {
  background: white;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(211, 211, 211, 0.47);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 1rem;
  width: 10rem;
  padding: 0.8rem;
}
.tooltip p {
  font-size: 12px;
}
</style>

<style scoped>
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
}
.dot-active {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
  padding: 2px;
}
.style-container {
  display: grid;
  grid-template-columns: 85% 1fr;
  grid-gap: 10px;
  align-content: center;
}
.guest-notice {
  display: grid;
  grid-template-columns: 5% 1fr;
  grid-gap: 15px;
  place-items: center;
  background: rgba(243, 243, 246, 0.5);
  border-radius: 4px;
  padding: 12px;
}
</style>