<template>
  <div>
    <label
      class="flex items-center my-1"
      v-for="option in options"
      :key="option"
    >
      <div class="mt-1">
        <svg
          v-if="selected === option"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0ZM11.1667 10.6667H1.83333V1.33333H11.1667V10.6667ZM10.4933 4L9.55333 3.05333L5.16 7.44667L3.44 5.73333L2.49333 6.67333L5.16 9.33333L10.4933 4Z"
            fill="#6B6873"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          @click="selectOption(option)"
        >
          <path
            d="M11.1667 1.33333V10.6667H1.83333V1.33333H11.1667ZM11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0Z"
            fill="#6B6873"
          />
        </svg>
      </div>
      <span class="ml-2 text-dark"> {{ option }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    selectOption(e) {
      this.selected = e;
      this.$emit("selected", this.selected);
    }
  }
};
</script>
